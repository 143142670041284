@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

.toastBody {
  font-family: 'Roboto', sans-serif;
  color: #01020A;
}

.toastProgress {
  background: #01020A !important;
}

.toastBodyDark {
  font-family: 'Roboto', sans-serif;
  color: #fff;
}

.toastProgressDark {
  background: #fff !important;
}

.textarea-container {
  position: relative;
}

.textarea {
  resize: none;
  overflow-y: hidden;
}

.textarea::-webkit-scrollbar {
  width: 0;
  background: transparent;
}